import React, {useEffect} from 'react';
import AOS from "aos";
import home from '../../images/home.png'
import { Link } from 'react-router-dom';
import './styles.scss'

export default function Home() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return  <div className='home-wrapper' id="home" data-aos="zoom-in">
      <div className='home-container'>
          <h1>Ultimate destination for all <br /> your proxy service needs</h1>
          <p>Our mission is to provide the best proxies at the cheapest <br />competitive prices!</p>
          <button><Link to={ {pathname:"https://dashboard.proxywrld.net/" }} target="_blank">Buy</Link></button>
          <img data-aos="zoom-out" src={home} alt="" />
      </div>
  </div>;
}
