import React, {useEffect, useState} from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import './styles.scss'

export default function PlanCard(props) {
    const {data} = props;
    const [selectValue, setSelectValue] = useState(data.p1)
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
 
  return (
    <div className='planCard-wrapper' data-aos="flip-down">
            <div className='title-container'><h3>{data.title}</h3></div>
            <ul>
                <li>{data.l1}</li>
                <li>{data.l2}</li>
                <li>{data.l3}</li>
                {data.l4 && <li>{data.l4}</li>}
                {data.l5 && <li>{data.l5}</li>}
                {data.l6 && <li>{data.l6}</li>}
                {data.l7 && <li>{data.l7}</li>}
                {data.l8 && <li>{data.l8}</li>}
                {data.l9 && <li>{data.l9}</li>}
                {data.l10 && <li>{data.l10}</li>}
                {data.l11 && <li>{data.l11}</li>}
            </ul>
           <p>Quantity</p>

           <div>
             <button className={selectValue === data.p1 ? 'selected' : "" } onClick={() => setSelectValue(data.p1)}>{data.opt1}</button>
             <button className={selectValue === data.p2 ? 'selected' : "" } onClick={() => setSelectValue(data.p2)}>{data.opt2}</button>
             <button className={selectValue === data.p3 ? 'selected' : "" } onClick={() => setSelectValue(data.p3)}>{data.opt3}</button>
             <button className={selectValue === data.p4 ? 'selected' : "" } onClick={() => setSelectValue(data.p4)}>{data.opt4}</button>
             {data.opt5 &&  <button className={selectValue === data.p5 ? 'selected' : "" } onClick={() => setSelectValue(data.p5)}>{data.opt5}</button>}
           </div>
           
            <div><h1>${selectValue}</h1></div>
           
            <a href="https://dashboard.proxywrld.net/"><button>Buy Now</button></a>
    </div>
  )
}
