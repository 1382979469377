import React from 'react'
import "aos/dist/aos.css";
import fast from '../../images/fast.png'
import low from '../../images/low.png'
import updated from '../../images/updated.png'
import best from '../../images/best.png'
import './styles.scss'
import FeaturesCard from './FeaturesCard/FeaturesCard';

export default function Features() {

  return (
    <div className='features-wrapper' id='features' >
      <div className='features-container'>
        <p>Whats the function</p>
        <h5>Features</h5>
        <div>
          {[
            {
              icon: fast,
              title: "Fast Speeds",
           
            },
            {
              icon: low,
              title: "Low to 0 Failure Rate",
          
            },
            {
              icon: updated,
              title: "Our proxies are updated regularly.",
          
            },
            {
              icon: best,
              title: "Best Customer Support",
          
            },
           
          ].map((item, i) => (
            <FeaturesCard key={i} data={item}/>
          ))}
        </div>
      </div>
    </div>
  )
}
