import React from 'react';
import Home from '../Home/Home'
import Plans from '../Plans/Plans'
import Features from '../Features/Features'
import Footer from '../../components/Footer/Footer'

export default function Main() {
  return <div>
      <Home />
      <Features />
      <Plans />
      <Footer />
  </div>;
}
