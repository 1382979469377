import React, {useState} from 'react'
import PlanCard from './PlanCard/PlanCard'
import './styles.scss'

export default function Plans() {
  const [clicked, setClicked] = useState('residential')
  return (
    <div className='plans-wrapper' id="pricing">
      <div className='plans-container'>
        <p>Pricing Plan</p>
        <h5>Choose your proxy plan ⚡️</h5>
        <div className='tabs-container'>
          <button className={clicked === 'residential' ? 'selected' : ""} onClick={()=>setClicked('residential')}>Residential</button>
          <button onClick={()=>setClicked('datacenter')} className={clicked === 'datacenter' ? 'selected' : ""}>Datacenter /ISP</button>
        </div>
        {clicked === 'residential'  && <div className='content'>
          {[
             {
              title: "Basic",
              l1: "Pools with 100 Million+ residential IPs",
              l2: "Independent IP pools",
              l3: "Unbanned on all sites",
              l4: "Footsites: <350ms⚡",
              l5: "YS: <350ms⚡",
              l6: "Shopify: <350ms⚡",
              l7: "Supreme: <350ms⚡",
              l8: "Nike: <350ms⚡",
              opt1: "1GB",
              opt2: "2GB",
              opt3: "4GB",
              opt4: "6GB",
              opt5: "10GB",
            
              p1: "2",
              p2: "4",
              p3: "8",
              p4: "12",
              p5: "20"
              
            },
            {
              title: "Premium",
              l1: "Pools with 70 Million+ premium IPs",
              l2: "Independent IP pools",
              l3: "Unbanned on all sites",
              l4: "Recommended only for retail sites",
              l5: "Optimized for US specifically",
              l6: "Footsites: <350ms⚡",
              l7: "YS: <350ms⚡",
              l8: "Shopify: <350ms⚡",
              l9: "Supreme: <350ms⚡",
              l10: "Nike: <350ms⚡",
              opt1: "1GB",
              opt2: "2GB",
              opt3: "4GB",
              opt4: "6GB",
              opt5: "10GB",
            
              p1: "10",
              p2: "20",
              p3: "40",
              p4: "60",
              p5: "100"
              
            },
            {
              title: "Elite",
              l1: "Private pools with 120 Million+ Ultra Premium IPs",
              l2: "Independent IP pools",
              l3: "Unbanned on all sites",
              l4: "Best for ANY USE CASE!",
              l5: "Optimized for ALL COUNTRIES",
              l6: "Amazing speeds and virgin IPS!",
              l7: "Footsites: <350ms⚡",
              l8: "YS: <350ms⚡",
              l9: "Shopify: <350ms⚡",
              l10: "Supreme: <350ms⚡",
              l11: "Nike: <350ms⚡",
              opt1: "1GB",
              opt2: "2GB",
              opt3: "4GB",
              opt4: "6GB",
              opt5: "10GB",
            
              p1: "16",
              p2: "32",
              p3: "64",
              p4: "96",
              p5: "160"
              
            },
       
          ].map((item, i)=> (
            <PlanCard key={i} data={item}/>
          ))}
        </div>}

        {clicked === 'datacenter'  && <div className='content'>
          {[
            {
              title: "Monthly ISP",
              l1: "Unbanned on all Sneaker websites (Not guaranteed on Footsites)",
              l2: "Supports all retail sites and MORE",
              l3: "10GPs Uplink",
              l4: "As low as 10ms on servers!",
              l5: "Amazing on Retail sites!",
              l6: "Hosted in Ashburn, VA",
              l7: "All Virgin IPs",
              l8: "Valid for 30 days from delivery",
              opt1: "25",
              opt2: "50",
              opt3: "75",
              opt4: "100",
            
              p1: "75",
              p2: "150",
              p3: "225",
              p4: "300",
          
              
            },
            {
              title: "Daily ISP",
              l1: "Unbanned on all Sneaker websites (Not guaranteed on Footsites)",
              l2: "Supports all retail sites and MORE",
              l3: "10GPs Uplink",
              l4: "As low as 10ms on servers!",
              l5: "Amazing on Retail sites!",
              l6: "Hosted in Ashburn, VA",
              l7: 'All Virgin IPs',
              l8: "Valid for 24 hours from delivery",
              opt1: "25",
              opt2: "50",
              opt3: "75",
              opt4: "100",
            
              p1: "25",
              p2: "50",
              p3: "75",
              p4: "100",
          
              
            },
        
          ].map((item, i)=> (
            <PlanCard key={i} data={item}/>
          ))}
        </div>}
      
      </div>
    </div>
  )
}
