import React, {useEffect} from 'react'
import AOS from "aos";
import './styles.scss'

export default function FeaturesCard(props) {
    const {data} = props;
    useEffect(() => {
      AOS.init();
      AOS.refresh();
    }, []);
  return (
    <div  className='featuresCard-wrapper'  data-aos="flip-up"
    data-aos-anchor-placement="center-bottom">
      <div className='top'>
       <img src={data.icon} alt="" />
       
      </div>
      <div className='bottom'> <h5>{data.title}</h5></div>
    </div>
  )
}
