import React, {useEffect} from 'react';
import AOS from "aos";
import twitter from '../../images/twitter.png'
import discord from '../../images/discord.png'
import './styles.scss'


export default function Footer() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <div className='footer-wrapper'>
      <div className='footer-content' >
        <div className='top'data-aos="fade-up" data-aos-anchor-placement="top-bottom">
          <div>
            <h5>Come Join the Winning Team</h5>
            <p>The only provider you will need to dominate every release.</p>
          </div>
         <div className='button-container'>
           <button><a href="https://dashboard.proxywrld.net/">Get Started</a></button>
         </div>
        </div>
        <div className='bottom'>
        
            <div>
              <a href="https://twitter.com/proxywrld"><img src={twitter} alt="" /></a>
              <a href="https://discord.gg/xRXDkuaWXh"><img src={discord} alt="" /></a>
              
            </div>
            <div className='operated'>
              <p>Operated by ProxyWRLD</p>
            </div>
            <div></div>
        
        </div>
      </div>
    </div>
  )
}
